import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {VideoInterface} from '../models/video';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataVideoApiService {

  constructor(private afs: AngularFirestore) {
  }

  private videoCollection: AngularFirestoreCollection<VideoInterface>;
  private videos: Observable<VideoInterface[]>;
  private videoDoc: AngularFirestoreDocument<VideoInterface>;
  // private video: Observable<VideoInterface>;
  public selectedVideo: VideoInterface = {
    id: null
  };

  private booksCollection: AngularFirestoreCollection<VideoInterface>;
  private books: Observable<VideoInterface[]>;

  getAllVideos() {
    this.videoCollection = this.afs.collection<VideoInterface>('videos', ref => ref.orderBy('posicion'));

    return this.videos = this.videoCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as VideoInterface;
          data.id = action.payload.doc.id;
          // console.log(data, 'DATAAAAAAAAAAAAAAAAAAAA');
          return data;
        });
      }));
  }

  getVideosporPosicionTaller (posicionInicial: number,posicionFinal: number) {
    this.videoCollection  = this.afs.collection<VideoInterface>('videos', ref => ref.where('posicion','>=',posicionInicial).where('posicion','<=',posicionFinal).orderBy('posicion'));
    return this.videos = this.videoCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as VideoInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }


  deleteVideo(idVideo: string): void {
    this.videoDoc = this.afs.doc<VideoInterface>(`videos/${idVideo}`);
    this.videoDoc.delete();
  }

  addVideo(video: VideoInterface): void {
    this.videoCollection.add(video);
  }

  updateVideo(video: VideoInterface): void {
    let idVideo = video.id;
    this.videoDoc = this.afs.doc<VideoInterface>(`videos/${idVideo}`);
    this.videoDoc.update(video);
  }
}
