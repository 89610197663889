import {Component, OnInit} from '@angular/core';
import {DataVideoApiService} from '../../services/data-video-api.service';
import {DomSanitizer} from '@angular/platform-browser';
import {map, filter} from 'rxjs/operators';
import {of, pipe} from 'rxjs';


@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {

  constructor(private dataApi: DataVideoApiService,
              public sanitizer: DomSanitizer) {
  }

  public Allvideos = [];
  public Allvideo = '';

  public videos = [];
  public video = '';

  public videos2 = [];
  public video2 = '';

  public videos3 = [];
  public video3 = '';

  // public arrayVideos: any = [];
  public arrayVideos2: any = [];

  ngOnInit() {

    // this.verTodosLosVideos();
    this.videosTallerOrientacion(1, 5);
    this.videosTallerSeguridadRiesgos(6, 11);
    this.videosTallerMeterologia(12, 15);

  }

  verTodosLosVideos() {
    this.dataApi.getAllVideos().subscribe(videos => {
      this.Allvideos = videos;
      this.montarIframe();
    });
  }


  videosTallerOrientacion(posicionInicial: number, posicionFinal: number) {
    this.dataApi.getVideosporPosicionTaller(posicionInicial, posicionFinal)
      .subscribe(videos => {
        // console.log(videos);
        this.videos = videos;
        this.montarIframeOrientacion();
      });
  }

  videosTallerSeguridadRiesgos(posicionInicial: number, posicionFinal: number) {
    this.dataApi.getVideosporPosicionTaller(posicionInicial, posicionFinal)
      .subscribe(videos => {
        this.videos2 = videos;
        this.montarIframeSeguridadRiesgos();
      });
  }

  videosTallerMeterologia(posicionInicial: number, posicionFinal: number) {
    this.dataApi.getVideosporPosicionTaller(posicionInicial, posicionFinal)
      .subscribe(videos => {
        this.videos3 = videos;
        this.montarIframeMeterologia();
      });
  }


  private montarIframe() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.videos.length; i++) {
      this.videos[i].iFrame = 'https://www.youtube.com/embed/' + this.videos[i].cod_video + '?rel=0&autoplay=1&controls=2&modestbranding=1';
    }
  }

  private montarIframeOrientacion() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.videos.length; i++) {
      this.videos[i].iFrame = 'https://www.youtube.com/embed/' + this.videos[i].cod_video + '?rel=0&autoplay=1&controls=2&modestbranding=1';
    }
  }

  private montarIframeSeguridadRiesgos() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.videos2.length; i++) {
      // tslint:disable-next-line:max-line-length
      this.videos2[i].iFrame = 'https://www.youtube.com/embed/' + this.videos2[i].cod_video + '?rel=0&autoplay=1&controls=2&modestbranding=1';
    }
  }

  private montarIframeMeterologia() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.videos3.length; i++) {
      // tslint:disable-next-line:max-line-length
      this.videos3[i].iFrame = 'https://www.youtube.com/embed/' + this.videos2[i].cod_video + '?rel=0&autoplay=1&controls=2&modestbranding=1';
    }
  }
}
