import {Component, OnInit} from '@angular/core';
import {DataUsersService} from '../../../services/data-users.service';
import {UserInterface} from '../../../models/user';
import {AuthService} from '../../../services/auth.service';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import {ExcelService} from '../../../services/excel.service';
import {Angular5Csv} from 'angular5-csv/dist/Angular5-csv';


@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.css']
})
export class ListUsersComponent implements OnInit {

  constructor(private dataApi: DataUsersService,
              private  authService: AuthService,
              private excelService: ExcelService) {
  }

  private users: UserInterface[];
  public isAdmin: any = null;
  public userUid: string = null;
  public camposUsuarios: any = [];

  ngOnInit() {
    this.getListUsers();

  }

  getListUsers() {
    this.dataApi.getAllUsers().subscribe(users => {
      this.users = users;
    });
  }

  exportAsPDF() {
    let data = document.getElementById('MyUserList');
    html2canvas(data).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf('l', 'cm', 'a4'); //Generates PDF in landscape mode
      // let pdf = new jspdf('p', 'cm', 'a4'); //Generates PDF in portrait mode
      pdf.addImage(contentDataURL, 'PNG', 0, 0, 29.7, 21.0);
      // pdf.addImage(contentDataURL, 'PNG', 1, 1, 19.9, 7.0);  //Tamaño Héctor
      let filename: string = 'Usuarios' + '_export_' + new Date().getTime();
      pdf.save(filename);
    });
  }

  exportAsXLSX(): void {
    this.filtrarCamposUsuarios();
    this.excelService.exportAsExcelFile(this.camposUsuarios, 'Usuarios');
  }

  exportAsCSV(): void {
    this.filtrarCamposUsuarios();

    let options = {
      headers: ['EMAIL']
    };

    let filename: string = 'Usuarios' + '_export_' + new Date().getTime();
    new Angular5Csv(this.camposUsuarios, filename, options);
  }

  filtrarCamposUsuarios() {
    // Generamos un array, para traernos solo los datos que necesitamos de usuarios y no traer todos los datos de los usuarios,
    // en este caso, solo es necesario el email
    let email: string = '';
    // let admin: boolean = false;
    // let editor: boolean = false;
    this.camposUsuarios = [];

    for (let i = 0; i < this.users.length; i++) {
      email = this.users[i].email;
      // admin = this.users[i].roles.admin;
      // editor = this.users[i].roles.editor;
      this.camposUsuarios.push({
        email: email
        // admin: admin,
        // editor: editor
      });
    }
  }
}
