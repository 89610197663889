import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/auth';
import {map, take, tap} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class VideosGuardGuard implements CanActivate {

  constructor(private afsAuth: AngularFireAuth,
              private router: Router,
              private authService: AuthService) {
  }

  public userUid: string = null;
  public role: any = false;
  public isAdmin: any = false;

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.afsAuth.authState
      .pipe(take(1))
      .pipe(map(authState => !!authState))
      .pipe(tap(auth => {

        if (!auth) {
          this.router.navigate(['/user/login']);
        }
        this.getRole();
      }));
  }

  async getRole() {
    await this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
          this.role = userRole.roles;
          this.isAdmin = this.role['admin'];

          if (!this.isAdmin) {
            this.router.navigate(['videos']);
          }
        });
      }

    });

  }
}
