import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavbarComponent} from './components/navbar/navbar.component';
import {LoginComponent} from './components/users/login/login.component';
import {ProfileComponent} from './components/users/profile/profile.component';
import {RegisterComponent} from './components/users/register/register.component';
import {Page404Component} from './components/page404/page404.component';
import {HomeComponent} from './components/home/home.component';
import {FormsModule} from '@angular/forms';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AngularFirestore} from '@angular/fire/firestore';
import { ListVideosComponent } from './components/admin/list-videos/list-videos.component';
import { VideosComponent } from './components/videos/videos.component';
import { UnsafeURLPipe } from './pipes/unsafe-url.pipe';
import { ModalVideoComponent } from './components/modal-video/modal-video.component';
import { ListUsersComponent } from './components/admin/list-users/list-users.component';
import {ExcelService} from './services/excel.service';
import {APP_BASE_HREF} from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    ProfileComponent,
    RegisterComponent,
    Page404Component,
    HomeComponent,
    ListVideosComponent,
    VideosComponent,
    UnsafeURLPipe,
    ModalVideoComponent,
    ListUsersComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireStorageModule
  ],
  providers: [
    AngularFireAuth,
    AngularFirestore,
    ExcelService,
    { provide: APP_BASE_HREF, useValue : '/'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
