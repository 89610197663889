import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {AngularFireAuth} from '@angular/fire/auth';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(private authService: AuthService,
              private AfsAuth: AngularFireAuth) {
  }

  // tslint:disable-next-line:variable-name
  public app_name = 'Fénix Activa';
  public isLogged = false;
  public userUid: string = null;
  public role: any = false;
  public isAdmin: any = null;

  ngOnInit() {
    this.getCurrentUser();
    this.getRole();
  }

  getCurrentUser() {
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.isLogged = true;
      } else {
        this.isLogged = false;
      }
    });
  }

  async getRole() {
    await this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
          this.role = userRole.roles;
          this.isAdmin = this.role['admin'];

        });
      }

    });

  }

  onLogout() {
    this.AfsAuth.auth.signOut();
  }
}
