import {Component, OnInit} from '@angular/core';
import {DataVideoApiService} from '../../../services/data-video-api.service';
import {VideoInterface} from '../../../models/video';
import {AuthService} from '../../../services/auth.service';


@Component({
  selector: 'app-list-videos',
  templateUrl: './list-videos.component.html',
  styleUrls: ['./list-videos.component.css']
})
export class ListVideosComponent implements OnInit {

  constructor(private dataApi: DataVideoApiService,
              private authService: AuthService) {
  }

  private videos: VideoInterface[];
  public isAdmin: any = null;
  public userUid: string = null;

  ngOnInit() {
    this.getListVideos();
    this.getCurrentUser();
  }

  getListVideos() {
    this.dataApi.getAllVideos().subscribe(videos => {
      this.videos = videos;
    });
  }

  onDeleteVideo(idVideo: string) {
    const confirmacion = confirm('¿Estas seguro de que quieres borrar el video?');
    if (confirmacion) {
      this.dataApi.deleteVideo(idVideo);
    }
  }

  onPreUpdateVideo(video: VideoInterface) {
    this.dataApi.selectedVideo = Object.assign({}, video);
  }

  async getCurrentUser() {
    await this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
          this.isAdmin = Object.assign({}, userRole.roles);
          this.isAdmin = this.isAdmin.hasOwnProperty('admin');
        });
      }
    });

  }
}
