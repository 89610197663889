import {Component, ElementRef, OnInit, ViewChild, Input} from '@angular/core';
import {DataVideoApiService} from '../../services/data-video-api.service';
import {VideoInterface} from '../../models/video';
import {NgForm} from '@angular/forms';


@Component({
  selector: 'app-modal-video',
  templateUrl: './modal-video.component.html',
  styleUrls: ['./modal-video.component.css']
})
export class ModalVideoComponent implements OnInit {

  constructor(private dataApi: DataVideoApiService) {
  }

  // @ts-ignore
  @ViewChild('btnCloseVideo') btnClose: ElementRef;
  @Input() userUid: string;

  ngOnInit() {
  }

  onSaveVideo(videoForm: NgForm) {
    if (videoForm.value.id == null) {
      videoForm.value.userUid = this.userUid;
      this.dataApi.addVideo(videoForm.value);
    } else {
      // Update
      this.dataApi.updateVideo(videoForm.value);
    }
    videoForm.resetForm();
    this.btnClose.nativeElement.click();
  }

}
