import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
// import {OffersComponent} from './components/offers/offers.component';
// import {DetailsBookComponent} from './components/details-book/details-book.component';
// import {ListBooksComponent} from './components/admin/list-books/list-books.component';
import {LoginComponent} from './components/users/login/login.component';
import {RegisterComponent} from './components/users/register/register.component';
import {ProfileComponent} from './components/users/profile/profile.component';
import {Page404Component} from './components/page404/page404.component';
import {AuthGuard} from './guards/auth.guard';
import {ListVideosComponent} from './components/admin/list-videos/list-videos.component';
import {VideosComponent} from './components/videos/videos.component';
import {VideosGuardGuard} from './guards/videos-guard.guard';
import {ListUsersComponent} from './components/admin/list-users/list-users.component';



const routes: Routes = [
  {path: '', component: HomeComponent},

  {path: 'videos', component: VideosComponent , canActivate: [ AuthGuard]}, // TODO: only user auth ( GUARDS ),
  {path: 'admin/list-videos', component: ListVideosComponent, canActivate: [VideosGuardGuard]},
  {path: 'admin/list-users', component: ListUsersComponent, canActivate: [VideosGuardGuard]},
  {path: 'user/login', component: LoginComponent},
  {path: 'user/register', component: RegisterComponent},
  {path: 'user/profile', component: ProfileComponent, canActivate: [AuthGuard]},
  {path: '**', component: Page404Component}
];

@NgModule({
  imports: [RouterModule.forRoot(routes , {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
