import {Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {AngularFireStorage} from '@angular/fire/storage';
import {finalize} from 'rxjs/operators';
import {Observable} from 'rxjs';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(private authService: AuthService,
              private router: Router,
              private storage: AngularFireStorage) {
  }

  @ViewChild('imageUser', {static: false}) inputImageUser: ElementRef;
  public email: string = '';
  public password: string = '';


  uploadPercent: Observable<number>;
  urlImage: Observable<string>;

  ngOnInit() {
  }

  onAddUser() {
    this.authService.registerUser(this.email, this.password)
      .then((() => {
        this.authService.isAuth().subscribe(user => {
          if (user) {
            user.updateProfile({
              displayName: '',
              photoURL: this.inputImageUser.nativeElement.value
            }).then(() => {
              // this.router.navigate(['admin/list-books']);
              this.router.navigate(['videos']);
            }).catch((error) => error);
          // }).catch((error) => console.log('error', error));
          }
        });
        // this.router.navigate(['admin/list-books']);
      })).catch(err => {
      window.alert('ERROR:' + err.message);
    });
  }

  onLoginGoogle(): void {
    this.authService.loginGoogleUser()
      .then(() => {
        this.onLoginRedirect();
      }).catch(err => {
      this.printaError(err);
    });
  }

  onLoginFacebook() {
    this.authService.loginFacebookUser()
      .then((() => {
        this.onLoginRedirect();
      })).catch(err => {
      this.printaError(err);
    });
  }

  onLoginRedirect(): void {
    this.router.navigate(['videos']);
  }

  printaError(err) {
    console.log('error', err.message);
  }

  onUpload(e) {
    // console.log('subir', e);
    // console.log('target', e.target.files[0]);
    // Creamos un id único para las imágenes
    const id = Math.random().toString(36).substring(2);
    const file = e.target.files[0];
    const filePath = `uploads/profile_${id}`;
    const ref = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    this.uploadPercent = task.percentageChanges();
    //Recuperar URL de la img
    task.snapshotChanges().pipe(finalize(() => {
      this.urlImage = ref.getDownloadURL();
    })).subscribe();

  }
}
