import {Component, OnInit} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(public afAuth: AngularFireAuth,
              private router: Router,
              private authService: AuthService) {
  }

  public email: string = '';
  public password: string = '';

  ngOnInit() {
  }

  onLogin(): void {
    this.authService.loginEmailUser(this.email, this.password)
      .then((() => {
        this.onLoginRedirect();
      })).catch((() => {
      // this.printaError(err);
    }));
  }

  onLoginGoogle(): void {
    this.authService.loginGoogleUser()
      .then((res) => {
        console.log('GOOGLE RES', res);
        this.onLoginRedirect();
      }).catch((err) => {
      this.printaError(err);
    });
  }

  onLoginFacebook() {
    this.authService.loginFacebookUser()
      .then((res => {
        console.log('FACEBOOK RESPUESTA', res);
        this.onLoginRedirect();
      })).catch((err) => {
      this.printaError(err);
    });
  }

  onLogout() {
    this.authService.logoutUser();
  }

  onLoginRedirect(): void {
    // this.router.navigate(['admin/list-books']);
    this.router.navigate(['videos']);
  }

  printaError(err) {
    // window.alert('ERROR:' + err.message);
    console.log('ERROR EN LA RESPUESTA', err.message);
  }


}
