import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {UserInterface} from '../models/user';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataUsersService {

  constructor(private afs: AngularFirestore) { }
  private userCollection: AngularFirestoreCollection<UserInterface>;
  private users: Observable<any>;
  private userDoc: AngularFirestoreDocument<UserInterface>;


  getAllUsers() {
    this.userCollection = this.afs.collection<UserInterface>('users');
    return this.users = this.userCollection.snapshotChanges()
      .pipe(map ( changes =>{
        return changes.map(action => {
          const data = action.payload.doc.data() as UserInterface;
          data.id = action.payload.doc.id;
          return data;
        })
      }))

  }

}
